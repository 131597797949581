@charset "UTF-8";
/*.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}*/
/*#particles-js {
  margin-top: -55px;
  width: 100%;
  height: 650px;
  background: #001c33 50% 50%;

  #conteudo-particles {
    position: absolute;
    margin-top: 55px;
    align-content: center;
  }
}*/
.conteudo-na-direita {
  text-align: right; }

.img-na-direita {
  float: right; }

.caixa-de-texto {
  max-width: 630px;
  text-align: justify; }
  .caixa-de-texto h3 {
    font-size: 2.1em;
    color: darkblue; }
  .caixa-de-texto p {
    font-size: 1.3em;
    color: #7d7d7d; }

#cabeca {
  position: relative;
  margin-top: -55px;
  padding-top: 95px;
  padding-bottom: 30px;
  background-color: #FFE6D5; }
  #cabeca #logo-home {
    visibility: hidden;
    width: 100%;
    margin-bottom: 30px; }
  #cabeca #wrap-cover-video {
    visibility: hidden; }
  #cabeca #cover-video-planeta {
    margin-bottom: 80px;
    transition: all 200ms; }
    #cabeca #cover-video-planeta:hover {
      box-shadow: 0 0 50px #000000;
      transform: scale(1.1); }
  #cabeca #img-ea {
    position: absolute;
    z-index: 99;
    height: 70px;
    bottom: -35px;
    left: calc(50% - 35px); }
  @media screen and (min-width: 800px) {
    #cabeca #img-ea {
      height: 100px;
      bottom: -50px;
      left: calc(50% - 50px); } }

#ensine-aprenda, #resolva, #elabore,
#premios, #simulado, #rodape {
  padding: 30px 0;
  overflow-x: hidden; }

#ensine-aprenda {
  position: relative;
  background-color: #F5F5F5;
  text-align: center; }
  #ensine-aprenda #conteudo-ensine-aprenda {
    visibility: hidden;
    width: 100%;
    max-width: 100%;
    text-align: center; }
    #ensine-aprenda #conteudo-ensine-aprenda #titulo-ea {
      margin: 50px 0 10px 0; }
  #ensine-aprenda button.botao-registrar {
    visibility: hidden;
    width: 100%;
    margin-top: 20px;
    background-color: #48BA86;
    color: white;
    font-size: 24px;
    text-align: center;
    border-radius: 4px;
    text-shadow: 1px 1px 1px grey; }
    #ensine-aprenda button.botao-registrar:hover {
      background-color: #39966c;
      font-weight: bold; }

.vem-da-esquerda, .vem-da-direita {
  visibility: hidden; }

.vem-da-esquerda {
  float: right; }

/* Tamanho letra na tela da figura notebook, em celulares */
.letra-na-tela {
  font-size: 10px; }

@media screen and (min-width: 500px) and (max-width: 991px) {
  .letra-na-tela {
    font-size: 12px; } }

@media screen and (min-width: 992px) {
  .letra-na-tela {
    font-size: 16px; } }

.img-wrap {
  visibility: hidden;
  position: relative;
  display: inline-block; }
  .img-wrap .img-responsive {
    max-height: 400px; }

#resolva {
  background-color: #e1e1e1; }
  #resolva #questao-tela {
    position: absolute;
    top: 28%;
    left: 25%;
    width: 50%;
    color: white; }

#elabore {
  background-color: #F5F5F5; }
  #elabore #digitando-resolucao {
    position: absolute;
    top: 5%;
    left: 50%; }

#premios {
  background-color: #aecbd8; }
  #premios #podio {
    padding-right: 50px; }
  #premios #guri {
    visibility: hidden;
    position: absolute;
    left: 10%;
    top: 1%; }

#simulado {
  background-color: #e1e1e1; }

#rodape {
  background-color: #111F2B; }

.botao, .botao-no-fundo-branco, .botao-hover-corprimaria, .botao-cor-primaria, .botao-desabilitado {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #ff6722;
  color: #ff6722;
  background: transparent;
  transition: all ease 0.5s; }
  .botao:hover, .botao-no-fundo-branco:hover, .botao-hover-corprimaria:hover, .botao-cor-primaria:hover, .botao-desabilitado:hover {
    background-color: rgba(245, 245, 241, 0.1); }
  .botao:disabled, .botao-no-fundo-branco:disabled, .botao-hover-corprimaria:disabled, .botao-cor-primaria:disabled, .botao-desabilitado:disabled, .botao [disabled], .botao-no-fundo-branco [disabled], .botao-hover-corprimaria [disabled], .botao-cor-primaria [disabled], .botao-desabilitado [disabled] {
    cursor: not-allowed; }

.botao-no-fundo-branco:hover {
  background-color: #eaeaea; }

.botao-hover-corprimaria:hover {
  background-color: #ff6722;
  border-color: white;
  color: white; }

.botao-cor-primaria {
  background-color: #ff6722;
  border-color: white;
  color: white; }
  .botao-cor-primaria:hover {
    background-color: #ff8a55; }

.botao-pequeno {
  font-size: 0.9em;
  padding: 4px 8px; }

.botao-desabilitado {
  cursor: not-allowed; }

.botao-50-esq {
  width: 49%;
  margin-top: 3px; }

.botao-50-dir {
  width: 49%;
  margin-top: 3px;
  float: right; }

.botao-100 {
  width: 100%;
  margin-top: 3px; }

.painel-filtros {
  background-color: #0B486B;
  margin-bottom: 30px; }
  .painel-filtros .botoes-auxiliares {
    margin-top: 8px; }
    .painel-filtros .botoes-auxiliares > a {
      color: #f5f5f5;
      text-decoration: none;
      cursor: pointer;
      outline: none; }
      .painel-filtros .botoes-auxiliares > a .link-painel-filtros {
        display: inline-block; }
        .painel-filtros .botoes-auxiliares > a .link-painel-filtros:hover {
          color: #ff6722;
          border-bottom: 2px solid #ff6722; }

[data-sidebar] {
  display: none;
  position: fixed;
  left: 0;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 50px;
  width: 290px;
  z-index: 100;
  background-color: #0B486B;
  box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.5); }

.painel-filtros {
  color: #f5f5f5;
  padding: 10px; }

aside a {
  color: #fff;
  text-decoration: none; }

.padding {
  padding: 2em; }

.titulo-painel-filtros {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #ff6722; }

.filtro-label {
  cursor: pointer;
  position: relative;
  margin-top: 20px; }
  .filtro-label span.label {
    font-weight: normal;
    font-size: 1.15em; }

#botao-aplicar-filtros {
  width: 100%;
  margin-right: 0; }

.selecionar-disciplinas {
  font-size: 0.8em;
  font-weight: 700;
  color: #d4d4d4; }

.sub-botao-filtros {
  position: absolute;
  width: 100%;
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 0.85em;
  font-weight: bold;
  line-height: .8em; }

.conteudo-deslocado {
  margin-left: 280px; }

.total-filtros-ativos {
  cursor: pointer;
  position: absolute;
  margin-top: 0;
  margin-left: -.3em;
  background-color: #888888; }

#total-filtros-ativos-index {
  position: absolute;
  top: -8px;
  right: -3px;
  font-size: 0.7em; }

#botao-painel-filtros {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: #0B486B;
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  padding-top: 15px;
  padding-bottom: 4px;
  padding-right: 4px;
  padding-left: 4px;
  left: -48px;
  top: 50%;
  font-weight: bold;
  font-size: 1.2em;
  transform: rotate(-90deg); }

.conteudo-pagina-questoes {
  width: 80%;
  margin: auto; }

.filtro-select {
  width: 100% !important; }

.select2-search, .select2-search--inline {
  width: 100%; }

/* Cor do placeholder */
.select2-search__field {
  color: #0F0F0F;
  width: 100% !important; }

.select2-selection__choice {
  text-shadow: none;
  color: #000;
  border-radius: 0 !important; }

.select2-selection, .select2-container, .select2-selection--multiple {
  border-radius: 0 !important; }

.select2-container--open {
  z-index: 999999 !important; }

.texto-filtro-slide, .texto-filtro-slide:hover {
  font-size: 1.2em;
  text-decoration: none;
  color: #fff;
  padding-top: 5px; }

.pocp_button:hover, .pocp_button:focus {
  color: #fff;
  text-decoration: none; }

.pocp_left, .pocp_show {
  top: 50px; }

.texto-filtro-slide:hover {
  text-decoration: none; }

.noti-bubble {
  position: absolute;
  cursor: pointer;
  margin-left: 4px;
  top: -3px;
  font-size: 0.7em; }

.icone-filtro {
  font-size: 1.5em; }

.label-mais-opcoes-filtros {
  font-size: 0.87em;
  color: #ffffff;
  margin-left: 0; }

.posts-container {
  /*.container-ckeditor {
    margin-bottom: 5px;
  }*/ }
  .posts-container .msg-anti-fraude {
    text-align: center;
    font-size: 0.8em;
    font-style: italic;
    margin-top: 5px;
    padding: 3px;
    background-color: #fff8d0; }
  @media (min-width: 992px) {
    .posts-container {
      padding: 5px 20px; } }
  .posts-container .input-de-post {
    font-size: 1.1em;
    color: dimgrey;
    margin-bottom: 5px; }
  .posts-container .margin-bottom-5 {
    margin-bottom: 5px; }
  .posts-container .imagem-post {
    max-width: 100%;
    display: inline-block; }
  .posts-container .sep {
    display: inline-block;
    margin: 0 2px;
    height: 12px;
    border-left: 1px solid #b9b9b9; }
  .posts-container .cabecalho {
    margin-top: 5px;
    font-size: 1.1em;
    font-weight: bold;
    color: dimgray; }
    .posts-container .cabecalho .contador {
      float: left;
      border-bottom: 3px solid #ff6722;
      margin-bottom: 3px; }
    .posts-container .cabecalho div.ordenar {
      float: right; }
      .posts-container .cabecalho div.ordenar > a {
        color: dimgray;
        text-decoration: none;
        border-bottom: 3px solid #ff6722; }
  .posts-container .novo-post {
    margin-top: 30px;
    margin-bottom: 5px;
    min-width: 100%;
    max-width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 100px; }
  .posts-container .botoes {
    margin-top: 5px;
    margin-bottom: 10px; }
  .posts-container .opcoes-cabecalho {
    display: inline-block;
    float: right;
    color: dimgrey;
    font-size: 1em;
    margin-right: 8px; }
    .posts-container .opcoes-cabecalho > i {
      cursor: pointer;
      margin-right: -4px;
      vertical-align: bottom; }
      .posts-container .opcoes-cabecalho > i:hover {
        color: #ff6722; }
  .posts-container .novo-comentario {
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    max-height: 100px;
    min-height: 54px;
    overflow-x: hidden;
    /* for Firefox (issue #5) */ }
  .posts-container .destacado {
    transition: border .1s ease;
    border-left: 5px solid #ff6722; }
  .posts-container div.colapsado {
    transition: all .5s ease;
    min-height: 50px; }
    .posts-container div.colapsado > div.avatar > img {
      width: 40px;
      height: 40px;
      margin-top: 0;
      margin-bottom: 5px; }
  .posts-container .post {
    position: relative;
    background-color: #f5f5f5;
    min-height: 80px;
    margin-top: 10px;
    padding: 5px;
    /* Só mostra avatar em MD e LG */ }
    .posts-container .post .avatar {
      display: none; }
    @media (min-width: 992px) {
      .posts-container .post {
        padding: 5px 10px 5px 80px; }
        .posts-container .post .avatar {
          height: 100%;
          display: inline-block;
          position: absolute;
          left: 0; }
          .posts-container .post .avatar img {
            transition: all .5s ease;
            width: 60px;
            height: 60px;
            margin: 10px;
            vertical-align: top;
            border-radius: 50%; } }
    .posts-container .post .post-corpo {
      word-wrap: break-word; }
      .posts-container .post .post-corpo .usuario {
        text-decoration: none;
        font-size: 1.1em;
        font-weight: bold;
        color: black;
        margin-right: 5px; }
      .posts-container .post .post-corpo .respondendo-nick {
        cursor: pointer;
        color: dimgray;
        font-size: 0.8em;
        margin-right: 5px;
        text-decoration: none; }
        .posts-container .post .post-corpo .respondendo-nick:hover {
          color: #ff6722; }
      .posts-container .post .post-corpo .postado-ha {
        font-size: 0.8em;
        color: dimgray;
        font-style: italic; }
      .posts-container .post .post-corpo .mais-opcoes {
        display: inline;
        float: right;
        color: dimgrey; }
        .posts-container .post .post-corpo .mais-opcoes i:hover {
          color: #ff6722; }
      .posts-container .post .post-corpo .post-conteudo {
        padding-top: 5px;
        text-align: justify; }
        .posts-container .post .post-corpo .post-conteudo table td, .posts-container .post .post-corpo .post-conteudo table tr {
          padding: 3px 5px; }
        .posts-container .post .post-corpo .post-conteudo .conteudo-oculto {
          font-size: 0.9em;
          color: dimgrey; }
        .posts-container .post .post-corpo .post-conteudo .exibir-conteudo {
          cursor: pointer;
          font-size: 0.8em;
          color: dimgrey;
          text-decoration: none; }
          .posts-container .post .post-corpo .post-conteudo .exibir-conteudo:hover {
            color: #ff6722; }
        .posts-container .post .post-corpo .post-conteudo .conteudo-materiais {
          display: -ms-flexbox;
          display: flex;
          margin: 3px;
          padding: 5px;
          background-color: white; }
          .posts-container .post .post-corpo .post-conteudo .conteudo-materiais i {
            -ms-flex-item-align: center;
            align-self: center;
            font-size: 1.8em;
            margin-right: 8px;
            color: #ff6722; }
          .posts-container .post .post-corpo .post-conteudo .conteudo-materiais a {
            -ms-flex-item-align: center;
            align-self: center;
            font-size: 1.1em;
            color: #333333; }
            .posts-container .post .post-corpo .post-conteudo .conteudo-materiais a:hover {
              color: #ff6722; }
        .posts-container .post .post-corpo .post-conteudo .iframe-video {
          max-width: 560px;
          width: 100%;
          height: 315px;
          overflow: auto; }
      .posts-container .post .post-corpo .rodape {
        display: block;
        position: relative; }
        .posts-container .post .post-corpo .rodape .votacao {
          display: inline-block;
          padding: 3px 6px;
          font-size: 0.8em;
          color: dimgray; }
          .posts-container .post .post-corpo .rodape .votacao .contador-up {
            font-weight: bold;
            color: #00af00; }
          .posts-container .post .post-corpo .rodape .votacao .contador-down {
            font-weight: bold;
            color: red; }
          .posts-container .post .post-corpo .rodape .votacao .voto-up {
            cursor: pointer;
            font-size: 1.2em; }
            .posts-container .post .post-corpo .rodape .votacao .voto-up:hover {
              color: #00af00; }
          .posts-container .post .post-corpo .rodape .votacao .votei-up {
            font-weight: bold;
            color: #00af00; }
          .posts-container .post .post-corpo .rodape .votacao .voto-down {
            cursor: pointer;
            font-size: 1.2em; }
            .posts-container .post .post-corpo .rodape .votacao .voto-down:hover {
              color: red; }
          .posts-container .post .post-corpo .rodape .votacao .votei-down {
            font-weight: bold;
            color: red; }
        .posts-container .post .post-corpo .rodape .link-responder {
          margin-left: 5px;
          font-size: 0.8em;
          color: dimgrey;
          text-decoration: none;
          cursor: pointer; }
          .posts-container .post .post-corpo .rodape .link-responder:hover {
            color: #ff6722; }
  .posts-container .comentario {
    margin-left: 15px; }
  .posts-container .resposta {
    margin-left: 30px;
    margin-top: 5px; }
  @media (min-width: 992px) {
    .posts-container .comentario {
      margin-left: 80px; }
    .posts-container .resposta {
      margin-left: 160px; } }

/*
Code by Creative Punch
 http://creative-punch.net/2014/02/create-css3-post-it-note/#comments
Create a CSS3 post-it note without images
*/
.quote-container {
  margin-top: 30px;
  position: relative;
  display: inline-table; }
  .quote-container .editando {
    border: 2px solid #2d7eb3;
    color: #0000b9; }
  .quote-container .yellow {
    background: #eae672;
    transform: rotate(2deg); }
  .quote-container .instrucoes-nova-anotacao {
    color: #0000b9; }
  .quote-container .pino {
    display: block;
    font-size: 1.6em;
    color: #3a474e;
    position: absolute;
    left: 50%;
    top: -14px;
    z-index: 99; }

.note {
  color: #333;
  position: relative;
  width: 330px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Calligraffiti', 'Open Sans', 'Helvetica', sans-serif;
  font-size: 1.5em;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3); }
  .note .conteudo {
    word-break: normal; }
  .note .rodape-post-it {
    margin-top: 10px;
    display: block;
    padding-bottom: 5px; }
    .note .rodape-post-it .criado-em {
      float: right;
      font-size: 0.6em; }
    .note .rodape-post-it .botoes-de-acao {
      font-family: 'Open Sans', 'Helvetica', sans-serif;
      float: left;
      font-size: 0.6em; }
      .note .rodape-post-it .botoes-de-acao a {
        cursor: pointer;
        text-decoration: none;
        color: #a5a153; }
        .note .rodape-post-it .botoes-de-acao a:hover {
          color: #0000b9; }
  .note .excluir-anotacao {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0.3em;
    cursor: pointer;
    color: #bab65e;
    font-size: 0.8em; }
    .note .excluir-anotacao:hover {
      color: red; }

.comentarios-container {
  padding: 5px 20px; }
  .comentarios-container .sep {
    display: inline-block;
    margin: 0 2px;
    height: 12px;
    border-left: 1px solid #b9b9b9; }
  .comentarios-container .cabecalho {
    font-size: 1.1em;
    font-weight: bold;
    color: dimgray; }
    .comentarios-container .cabecalho .contador {
      float: left;
      border-bottom: 3px solid #ff6722; }
    .comentarios-container .cabecalho div.ordenar {
      float: right; }
      .comentarios-container .cabecalho div.ordenar > a {
        color: dimgray;
        text-decoration: none;
        border-bottom: 3px solid #ff6722; }
  .comentarios-container .novo-comentario {
    margin-top: 30px;
    margin-bottom: 5px;
    min-width: 100%;
    max-width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 100px; }
  .comentarios-container .toggle-todas-respostas {
    float: right;
    font-size: 0.7em;
    color: dimgrey;
    cursor: pointer;
    margin-top: -3px;
    text-decoration: none; }
    .comentarios-container .toggle-todas-respostas:hover {
      color: #ff6722; }
  .comentarios-container .nova-resposta {
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 100%;
    max-width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 100px; }
  .comentarios-container .comentario-destacado {
    transition: border .1s ease;
    border-left: 5px solid #ff6722; }
  .comentarios-container div.comentario-colapsado {
    transition: all .5s ease;
    min-height: 50px; }
    .comentarios-container div.comentario-colapsado > div.avatar > img {
      width: 40px;
      height: 40px;
      margin-top: 0;
      margin-bottom: 5px; }
  .comentarios-container .comentario {
    position: relative;
    background-color: #f3f3f3;
    min-height: 80px;
    padding: 5px 10px 5px 80px;
    margin-top: 15px; }
    .comentarios-container .comentario .avatar {
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 0; }
      .comentarios-container .comentario .avatar img {
        transition: all .5s ease;
        width: 60px;
        height: 60px;
        margin: 10px;
        vertical-align: top;
        border-radius: 50%; }
    .comentarios-container .comentario .comentario-corpo .usuario {
      text-decoration: none;
      font-size: 1.1em;
      font-weight: bold;
      color: black;
      margin-right: 5px; }
    .comentarios-container .comentario .comentario-corpo .respondendo-nick {
      cursor: pointer;
      color: dimgray;
      font-size: 0.8em;
      margin-right: 5px;
      text-decoration: none; }
      .comentarios-container .comentario .comentario-corpo .respondendo-nick:hover {
        color: #ff6722; }
    .comentarios-container .comentario .comentario-corpo .postado-ha {
      font-size: 0.8em;
      color: dimgray;
      font-style: italic; }
    .comentarios-container .comentario .comentario-corpo .mais-opcoes {
      display: inline;
      float: right;
      color: dimgrey; }
      .comentarios-container .comentario .comentario-corpo .mais-opcoes i:hover {
        color: #ff6722; }
    .comentarios-container .comentario .comentario-corpo .comentario-texto {
      padding-top: 5px;
      text-align: justify; }
    .comentarios-container .comentario .comentario-corpo .rodape {
      display: block;
      position: relative; }
      .comentarios-container .comentario .comentario-corpo .rodape .votacao {
        display: inline-block;
        padding: 3px 6px;
        font-size: 0.8em;
        color: dimgray; }
        .comentarios-container .comentario .comentario-corpo .rodape .votacao .contador-up {
          font-weight: bold;
          color: #00af00; }
        .comentarios-container .comentario .comentario-corpo .rodape .votacao .contador-down {
          font-weight: bold;
          color: red; }
        .comentarios-container .comentario .comentario-corpo .rodape .votacao .voto-up {
          cursor: pointer;
          font-size: 1.2em; }
          .comentarios-container .comentario .comentario-corpo .rodape .votacao .voto-up:hover {
            color: #00af00; }
        .comentarios-container .comentario .comentario-corpo .rodape .votacao .votei-up {
          font-weight: bold;
          color: #00af00; }
        .comentarios-container .comentario .comentario-corpo .rodape .votacao .voto-down {
          cursor: pointer;
          font-size: 1.2em; }
          .comentarios-container .comentario .comentario-corpo .rodape .votacao .voto-down:hover {
            color: red; }
        .comentarios-container .comentario .comentario-corpo .rodape .votacao .votei-down {
          font-weight: bold;
          color: red; }
      .comentarios-container .comentario .comentario-corpo .rodape .link-responder {
        margin-left: 5px;
        font-size: 0.8em;
        color: dimgrey;
        text-decoration: none;
        cursor: pointer; }
        .comentarios-container .comentario .comentario-corpo .rodape .link-responder:hover {
          color: #ff6722; }
  .comentarios-container .resposta {
    margin-left: 80px;
    margin-top: 5px; }

.painel-estatisticas {
  text-align: center; }
  .painel-estatisticas .grafico-estatisticas {
    display: inline-block;
    padding: 0;
    margin: 0 auto; }
    @media screen and (min-width: 768px) {
      .painel-estatisticas .grafico-estatisticas {
        padding: 5px 50px; } }
    .painel-estatisticas .grafico-estatisticas .titulo-grafico {
      text-align: center;
      font-size: 1.3em;
      margin-bottom: 5px; }

#pagina-questoes .titulo-pagina, #pagina-provas .titulo-pagina {
  font-size: 1.8em; }

#pagina-questoes .botoes-cabecalho, #pagina-provas .botoes-cabecalho {
  font-size: 1.1em;
  padding-top: 10px; }

.ref-texto {
  font-size: 0.9em;
  text-align: right; }

.ref-imagem {
  font-size: 0.9em;
  text-align: center; }

.ref-tabela {
  font-size: 0.9em;
  text-align: center; }

.img-questao {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.img-30 {
  min-width: 30%; }

.img-50 {
  min-width: 50%; }

.img-80 {
  min-width: 80%; }

.img-100 {
  min-width: 100%; }

#pagina-questoes {
  height: 100%;
  overflow-y: scroll; }

#paineis, #conteudo-questoes-provas {
  height: 100%;
  padding: 0; }

#paginacao-qpp {
  min-height: 45px; }

.cabecalho-lista {
  margin-top: 10px; }

#carregando-questoes-provas {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  text-align: center;
  color: #ff6722; }
  #carregando-questoes-provas i {
    font-size: 80px; }

.nada-encontrado {
  margin-top: 60px !important;
  padding: 10px;
  text-align: center; }
  .nada-encontrado div.corpo {
    height: 100%; }
    .nada-encontrado div.corpo i.fa {
      display: block;
      font-size: 8em;
      color: #ff6722; }
    .nada-encontrado div.corpo p {
      color: #333333;
      font-size: 1.5em; }

.painel-questao {
  position: relative;
  background-color: white;
  margin-top: 0;
  margin-bottom: 35px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4); }
  .painel-questao > .cabecalho {
    font-size: 0.85em;
    color: #515151;
    height: auto;
    background-color: #f7f6f6;
    border-bottom: 1px solid #0B486B; }
    @media (min-width: 768px) {
      .painel-questao > .cabecalho {
        font-size: 1.2em; } }
    .painel-questao > .cabecalho > .numero-questao {
      display: inline-block;
      background-color: #0B486B;
      color: white;
      text-align: center;
      padding: 4px 8px; }
    .painel-questao > .cabecalho > .acertou-questao {
      box-shadow: -5px -5px 0 0 green; }
    .painel-questao > .cabecalho > .errou-questao {
      box-shadow: -5px -5px 0 0 #cb1919; }
    .painel-questao > .cabecalho > .links {
      display: inline-block;
      float: right;
      cursor: pointer;
      font-size: 1.3em;
      padding: 0;
      margin-right: 4px; }
      .painel-questao > .cabecalho > .links > .grupo-links {
        color: #0B486B;
        margin: 0;
        padding: 0;
        vertical-align: top; }
      .painel-questao > .cabecalho > .links > .icone {
        padding-right: 3px; }
  .painel-questao .sub-cabecalho {
    background-color: #F5F5F5;
    padding: 5px;
    font-size: 0.9em; }
    @media (min-width: 768px) {
      .painel-questao .sub-cabecalho {
        padding: 5px 15px;
        font-size: 1em; } }

.questao-tabs > li.active > a {
  color: #ff6722;
  font-weight: bold; }

.questao-tabs > li > a {
  cursor: pointer;
  color: #333333; }
  .questao-tabs > li > a:hover {
    color: #ff6722;
    background-color: #f8f8f8; }

@media (max-width: 768px) {
  .questao-tabs > li {
    display: inline-block;
    width: 18.9%; }
    .questao-tabs > li .tab-label {
      display: none; }
    .questao-tabs > li.active > a, .questao-tabs > li.active > a:active {
      border-bottom: none; } }

.questao-tabs .tab-label {
  position: relative;
  font-size: 1.1em; }

.questao-tabs .tab-contador {
  position: absolute;
  top: -2px;
  left: 2px;
  font-size: 0.5em; }

.carregando-tab {
  text-align: center;
  color: #ff6722;
  font-size: 3em; }

.conteudo-tab {
  display: none;
  background-color: white;
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid #aeaeae; }

.discorda {
  position: relative;
  top: -6px;
  left: 2px;
  font-size: 0.65em; }

.icone:hover {
  color: #ff6722; }

.painel-questao > .cabecalho > .descricao {
  display: inline;
  padding: 4px; }

.painel-questao > .cabecalho > .descricao > .pipe {
  border: 0.05em solid #afafaf;
  height: 100%;
  margin-left: 0.4em;
  margin-right: 0.4em; }

.painel-questao > .corpo {
  background-color: white;
  padding: 5px; }
  @media (min-width: 768px) {
    .painel-questao > .corpo {
      padding: 5px 10px; } }
  .painel-questao > .corpo .texto, .painel-questao > .corpo .enunciado, .painel-questao > .corpo .alternativas {
    padding: 5px;
    margin-top: 5px; }
  .painel-questao > .corpo .link-ver-esconder-texto {
    cursor: pointer;
    color: #ff6722; }
  .painel-questao > .corpo .botao-responder-container {
    margin-top: 5px; }
    .painel-questao > .corpo .botao-responder-container .ver-gabarito {
      font-size: 0.9em;
      color: dimgray;
      vertical-align: bottom;
      text-decoration: none; }
    .painel-questao > .corpo .botao-responder-container a.ver-gabarito {
      cursor: pointer; }
      .painel-questao > .corpo .botao-responder-container a.ver-gabarito:hover {
        color: #ff6722; }
    .painel-questao > .corpo .botao-responder-container .msg-resposta {
      font-weight: bold;
      color: white;
      border-color: white; }
    .painel-questao > .corpo .botao-responder-container .errou {
      background-color: #cb1919; }
    .painel-questao > .corpo .botao-responder-container .acertou {
      background-color: green; }
    .painel-questao > .corpo .botao-responder-container .botao-responder-questao {
      background-color: #ff6722;
      font-weight: bold;
      color: white; }
      .painel-questao > .corpo .botao-responder-container .botao-responder-questao:hover {
        background-color: #ff5509; }
  .painel-questao > .corpo .aviso-discursiva-redacao {
    margin-top: 5px;
    font-style: italic;
    font-size: 0.85em; }
  .painel-questao > .corpo .alternativas .alternativa-selecionada {
    box-shadow: inset 0 0 0 2px #ff6722;
    background-color: rgba(255, 103, 34, 0.12); }
    .painel-questao > .corpo .alternativas .alternativa-selecionada i.fa {
      color: #ff6722; }
  .painel-questao > .corpo .alternativas .alternativa-certa {
    box-shadow: inset 0 0 0 2px green;
    background-color: rgba(0, 255, 0, 0.12); }
    .painel-questao > .corpo .alternativas .alternativa-certa i.fa {
      color: green; }
  .painel-questao > .corpo .alternativas .alternativa-errada {
    box-shadow: inset 0 0 0 2px #cb1919;
    background-color: rgba(255, 0, 0, 0.12); }
    .painel-questao > .corpo .alternativas .alternativa-errada i.fa {
      color: #cb1919; }
  .painel-questao > .corpo .alternativas .alternativa {
    position: relative;
    cursor: pointer;
    min-height: 36px;
    vertical-align: middle;
    padding: 4px 0; }
    .painel-questao > .corpo .alternativas .alternativa:hover {
      background-color: rgba(255, 103, 34, 0.08); }
    .painel-questao > .corpo .alternativas .alternativa .alternativa-id {
      position: absolute;
      left: 4px;
      top: 4px;
      cursor: pointer;
      /* Letra, dentro do circulo */ }
      .painel-questao > .corpo .alternativas .alternativa .alternativa-id:hover {
        color: #ff6722; }
      .painel-questao > .corpo .alternativas .alternativa .alternativa-id > i, .painel-questao > .corpo .alternativas .alternativa .alternativa-id > span {
        line-height: 100%; }
      .painel-questao > .corpo .alternativas .alternativa .alternativa-id > i {
        font-size: 28px; }
      .painel-questao > .corpo .alternativas .alternativa .alternativa-id > span {
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        top: 6px;
        left: 7px; }
    .painel-questao > .corpo .alternativas .alternativa .alternativa-texto {
      display: inline-block;
      height: 100%;
      padding-left: 34px; }

.compartilhar-facebook:hover {
  color: #3b5998; }

.compartilhar-twitter:hover {
  color: #00aced; }

.compartilhar-google:hover {
  color: #dd4b39; }

.compartilhar-pinterest:hover {
  color: #cb2027; }

.compartilhar-link:hover {
  color: #0edd25; }

.questoes-por-pagina {
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 7px;
  color: #333333; }

.cabecalho-questao {
  margin-left: 20px;
  font-size: 1.1em; }

.cabecalho-questao > .item {
  border-color: #5e5e5e;
  border-left: 1px solid;
  padding-left: 5px;
  margin-right: 5px; }

.tarja-questao {
  background-color: #d4d4d4;
  padding: 3px 15px;
  font-size: 1em; }

.tarja-questao > span {
  margin-right: 20px; }

#descricao-problema {
  min-width: 100%;
  max-width: 100%;
  min-height: 80px;
  color: #5e5e5e; }

#pagina-provas {
  height: 100%;
  overflow-y: scroll; }

#paineis-de-questoes, #questoes-conteudo {
  height: 100%; }

div.prova {
  position: relative;
  margin: 0 0 2em 0;
  background: #fff;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  /*height:300px;*/
  /*width: 100%;*/
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.18); }
  div.prova > div.prova-cover {
    background-color: #F5F5F5;
    color: #333333; }
    div.prova > div.prova-cover hr {
      margin-bottom: 8px; }
    div.prova > div.prova-cover > span.calendario-ano {
      position: absolute;
      top: 10px;
      left: 10px;
      color: #ff6722; }
      div.prova > div.prova-cover > span.calendario-ano > i.ano {
        position: absolute;
        top: 23px;
        right: 9px;
        font-size: 16px;
        font-weight: bold; }
    div.prova > div.prova-cover > span.icones-de-canto {
      position: absolute;
      top: 10px;
      right: 10px;
      color: lightgrey; }
      div.prova > div.prova-cover > span.icones-de-canto > i {
        cursor: pointer;
        font-size: 18px; }
        div.prova > div.prova-cover > span.icones-de-canto > i:hover {
          color: #ff6722; }
    div.prova > div.prova-cover > div.cabecalho div.sub-cabecalho {
      background-color: #ABE0F5;
      color: #0064af;
      height: 160px;
      padding: 5px; }
      div.prova > div.prova-cover > div.cabecalho div.sub-cabecalho > h2 {
        margin-top: 15px; }
      div.prova > div.prova-cover > div.cabecalho div.sub-cabecalho > p {
        font-size: 1.1em;
        line-height: 1em;
        color: #737373; }
    div.prova > div.prova-cover div.data-aplicacao > span {
      float: right;
      font-style: italic;
      font-size: 0.9em;
      margin-bottom: 15px;
      margin-right: 10px; }
    div.prova > div.prova-cover div.botoes {
      text-align: center; }
      div.prova > div.prova-cover div.botoes > a {
        cursor: pointer;
        padding: 5px 10px; }
        div.prova > div.prova-cover div.botoes > a:hover {
          text-decoration: none; }
    div.prova > div.prova-cover div.logo-prova > img {
      margin: 0 auto 5px auto;
      max-height: 100px; }
    div.prova > div.prova-cover div.logo-textual {
      text-align: center;
      padding: 0 50px;
      margin: 0 auto 5px auto;
      font-size: 1.8em;
      height: 100px; }

.prova:before {
  z-index: -1;
  position: absolute;
  top: -2px;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.19);
  transform: rotate(1deg); }

.prova:after {
  z-index: -2;
  position: absolute;
  top: 1px;
  left: -2px;
  content: '';
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  transform: rotate(358deg); }

#conteudo-simulado {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100% !important; }

#pagina-simulados {
  height: 100%  !important;
  padding: 0; }
  #pagina-simulados .container-fluid {
    padding: 0; }

#grafico-percentual {
  width: 300px;
  height: 300px;
  margin: auto; }

#grafico-tempo {
  width: 350px;
  height: 300px;
  margin: auto; }

#janela-simulado {
  z-index: 9999;
  background-color: #333333;
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 0;
  /* fecha #modo-simulado*/ }
  #janela-simulado div#modo-simulado {
    position: relative;
    padding: 35px 0 0 0;
    width: 100%;
    height: 100% !important; }
    #janela-simulado div#modo-simulado hr.separador-cor-primaria {
      border: 2px solid #ff6722;
      margin: 25px 0; }
    #janela-simulado div#modo-simulado div.questao {
      position: relative;
      margin-top: 30px;
      background-color: white;
      color: #333333;
      border: 1px solid dimgrey;
      font-size: 1em;
      /* Não mostra as setas de PREV e NEXT em aparelhos SM e XS */
      /* "garante o espaço" pro botao */ }
      @media only screen and (max-width: 991px) {
        #janela-simulado div#modo-simulado div.questao span.prev-next {
          display: none; } }
      #janela-simulado div#modo-simulado div.questao span.prev-next {
        cursor: pointer;
        color: white;
        position: absolute;
        top: calc(50% - 20px);
        font-size: 40px;
        padding: 0;
        margin: 0; }
        #janela-simulado div#modo-simulado div.questao span.prev-next:hover {
          color: #ff6722; }
      #janela-simulado div#modo-simulado div.questao span.prev {
        left: -40px; }
      #janela-simulado div#modo-simulado div.questao span.next {
        right: -40px; }
      #janela-simulado div#modo-simulado div.questao div.titulo {
        font-size: 1.2em;
        color: white;
        font-weight: bold;
        background-color: #27AE60;
        padding: 4px 5px;
        margin-bottom: 5px;
        /* Não mostra disciplina em aparelhos SM e XS */ }
        #janela-simulado div#modo-simulado div.questao div.titulo span.disc {
          font-weight: normal;
          font-size: 1em; }
        @media only screen and (max-width: 991px) {
          #janela-simulado div#modo-simulado div.questao div.titulo span.disc {
            display: none; } }
        #janela-simulado div#modo-simulado div.questao div.titulo a.link-questao {
          color: white;
          float: right; }
      #janela-simulado div#modo-simulado div.questao div.enunciado {
        padding: 5px; }
      #janela-simulado div#modo-simulado div.questao div.texto {
        padding: 5px; }
        #janela-simulado div#modo-simulado div.questao div.texto a.abre-texto {
          color: #ff6722;
          cursor: pointer; }
        #janela-simulado div#modo-simulado div.questao div.texto div.texto-conteudo {
          padding: 5px;
          background-color: #F2F2F2; }
      #janela-simulado div#modo-simulado div.questao div.alternativas {
        padding: 5px; }
        #janela-simulado div#modo-simulado div.questao div.alternativas .margem-extra {
          margin-top: 2px; }
        #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa-selecionada {
          box-shadow: inset 0 0 0 2px #ff6722;
          color: #ff6722;
          background-color: rgba(255, 103, 34, 0.12); }
          #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa-selecionada i.fa {
            color: #ff6722; }
        #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa-certa {
          box-shadow: inset 0 0 0 2px green;
          color: green;
          background-color: rgba(0, 255, 0, 0.12); }
          #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa-certa i.fa {
            color: green; }
        #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa-errada {
          box-shadow: inset 0 0 0 2px #cb1919;
          color: #cb1919;
          background-color: rgba(255, 0, 0, 0.12); }
          #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa-errada i.fa {
            color: #cb1919; }
        #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa {
          position: relative;
          cursor: pointer;
          min-height: 36px;
          vertical-align: middle;
          padding: 4px 0; }
          #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa:hover {
            background-color: rgba(255, 103, 34, 0.08); }
          #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-id {
            position: absolute;
            left: 4px;
            top: 4px;
            cursor: pointer;
            /* Letra, dentro do circulo */ }
            #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-id:hover {
              color: #ff6722; }
            #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-id > i, #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-id > span {
              line-height: 100%; }
            #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-id > i {
              font-size: 28px; }
            #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-id > span {
              position: absolute;
              font-size: 14px;
              font-weight: bold;
              top: 6px;
              left: 7px; }
          #janela-simulado div#modo-simulado div.questao div.alternativas .alternativa .alternativa-texto {
            display: inline-block;
            height: 100%;
            padding-left: 34px; }
      #janela-simulado div#modo-simulado div.questao div.prosseguir {
        min-height: 36px; }
    #janela-simulado div#modo-simulado div#cabecalho {
      width: 100%;
      text-align: center; }
      #janela-simulado div#modo-simulado div#cabecalho button.btn-cabecalho {
        padding: 6px 15px;
        font-size: 1.2em;
        color: white;
        border-color: white; }
      #janela-simulado div#modo-simulado div#cabecalho span#sair {
        float: left;
        color: white;
        font-size: 32px;
        cursor: pointer; }
        #janela-simulado div#modo-simulado div#cabecalho span#sair:hover {
          color: #cb1919; }
      #janela-simulado div#modo-simulado div#cabecalho span#finalizar {
        float: right;
        color: white;
        font-size: 32px;
        cursor: pointer; }
        #janela-simulado div#modo-simulado div#cabecalho span#finalizar:hover {
          color: #27AE60; }
      #janela-simulado div#modo-simulado div#cabecalho div#relogio {
        display: inline-block;
        position: relative;
        height: 40px;
        width: 150px;
        overflow: hidden;
        cursor: default; }
        #janela-simulado div#modo-simulado div#cabecalho div#relogio div#icone-relogio {
          height: 40px;
          width: 150px;
          padding: 0 55px;
          /* a img só tem 40px de largura */ }
        #janela-simulado div#modo-simulado div#cabecalho div#relogio div#timer {
          height: 40px;
          padding-top: 10px;
          width: 150px;
          text-align: center;
          font-size: 1.6em;
          vertical-align: middle; }
          #janela-simulado div#modo-simulado div#cabecalho div#relogio div#timer span.pequeno {
            font-size: 0.9em; }
    #janela-simulado div#modo-simulado div#indice-questoes {
      position: relative;
      margin: 15px auto 20px auto;
      height: 30px;
      width: 100%;
      overflow: hidden;
      text-align: center; }
      #janela-simulado div#modo-simulado div#indice-questoes ul.inline-ul {
        padding: 0;
        margin: 0;
        list-style: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
      #janela-simulado div#modo-simulado div#indice-questoes li {
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 40px;
        font-size: 20px;
        text-align: center;
        vertical-align: middle;
        color: white;
        border: 1px solid #9b9b9b; }
        #janela-simulado div#modo-simulado div#indice-questoes li:hover {
          background-color: #ff6722;
          color: white; }
      #janela-simulado div#modo-simulado div#indice-questoes li.setinha {
        width: 25px; }
      #janela-simulado div#modo-simulado div#indice-questoes li.atual {
        background-color: #ff6722;
        border-color: white;
        color: white; }
      #janela-simulado div#modo-simulado div#indice-questoes li.resolvida {
        border-color: #27AE60;
        color: #27AE60; }
    #janela-simulado div#modo-simulado div#indice-completo {
      position: relative; }
      #janela-simulado div#modo-simulado div#indice-completo div#conteudo-indice-completo {
        display: none;
        z-index: 99995;
        position: absolute;
        height: 38px;
        /* será calculada e ajustada de acordo com o num de Q */
        width: 340px;
        top: -15px;
        left: calc(50% - 170px);
        text-align: center;
        background-color: #555555; }
        #janela-simulado div#modo-simulado div#indice-completo div#conteudo-indice-completo ul {
          padding: 0;
          margin: 0;
          list-style: none; }
        #janela-simulado div#modo-simulado div#indice-completo div#conteudo-indice-completo li {
          display: inline-block;
          color: white;
          text-align: center;
          margin: 4px 3px 0 2px;
          padding: 3px 0;
          height: 28px;
          width: 28px;
          background-color: #333333;
          border: 1px solid #b9b9b9; }
          #janela-simulado div#modo-simulado div#indice-completo div#conteudo-indice-completo li:hover {
            background-color: #ff6722;
            color: white; }
        #janela-simulado div#modo-simulado div#indice-completo div#conteudo-indice-completo li.atual {
          background-color: #ff6722;
          border-color: white;
          color: white; }
        #janela-simulado div#modo-simulado div#indice-completo div#conteudo-indice-completo li.resolvida {
          border-color: #27AE60;
          color: #27AE60; }
      #janela-simulado div#modo-simulado div#indice-completo i {
        position: absolute;
        cursor: pointer;
        font-size: 16px;
        left: calc(50% - 8px);
        color: white; }
      #janela-simulado div#modo-simulado div#indice-completo i.abre-indice {
        bottom: 0; }
      #janela-simulado div#modo-simulado div#indice-completo i.fecha-indice {
        z-index: 99995;
        display: none;
        color: #ff6722; }
    #janela-simulado div#modo-simulado div#barra-status {
      position: absolute;
      left: 0;
      top: 0;
      height: 20px;
      width: 100%;
      background-color: #333333; }
      #janela-simulado div#modo-simulado div#barra-status div#progresso-wrap {
        position: relative;
        width: 100%;
        height: 18px;
        border: 1px solid white; }
        #janela-simulado div#modo-simulado div#barra-status div#progresso-wrap div#progresso {
          background-color: #27AE60;
          height: 16px;
          width: 0;
          float: left; }
        #janela-simulado div#modo-simulado div#barra-status div#progresso-wrap span.info-progressbar {
          position: absolute;
          width: 200px;
          text-align: center;
          left: calc(50% - 100px);
          top: 0;
          color: white;
          font-size: 15px;
          line-height: 15px; }
    #janela-simulado div#modo-simulado #aguardando-resultados {
      position: absolute;
      width: 300px;
      top: 40%;
      right: calc(50% - 150px);
      text-align: center;
      color: #ff6722; }
      #janela-simulado div#modo-simulado #aguardando-resultados i.fa {
        font-size: 90px; }
    #janela-simulado div#modo-simulado div#resultados-simulado {
      width: 100%;
      height: 100% !important;
      color: white; }
      #janela-simulado div#modo-simulado div#resultados-simulado div#sair-simulado-finalizado {
        width: 100%; }
        #janela-simulado div#modo-simulado div#resultados-simulado div#sair-simulado-finalizado button {
          float: right; }
      #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos {
        width: 100%;
        color: white;
        text-align: center;
        font-size: 1em;
        margin-top: 25px; }
        #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos th {
          background-color: #27AE60;
          text-align: center;
          font-size: 0.9em; }
        #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos tbody {
          cursor: pointer; }
          #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos tbody td > i.fa {
            font-size: 18px; }
          #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos tbody tr:nth-child(even) {
            background: #4b4b4b; }
          #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos tbody tr:hover, #janela-simulado div#modo-simulado div#resultados-simulado table#tabela-gabaritos tbody tr.selecionada {
            background-color: #ff6722; }
    #janela-simulado div#modo-simulado div.vamos-comecar {
      position: absolute;
      width: 100%;
      height: 270px;
      top: 30%;
      /*top: 40%;
      left: calc(50% - 150px);
      margin-top: 20%;*/
      display: none;
      text-align: center;
      color: white; }
      #janela-simulado div#modo-simulado div.vamos-comecar i.fa {
        position: absolute;
        top: 0;
        left: calc(50% - 40px);
        font-size: 80px;
        color: #ff6722; }
      #janela-simulado div#modo-simulado div.vamos-comecar div.tudo-pronto {
        position: absolute;
        top: 0;
        left: 0;
        right: 0; }
      #janela-simulado div#modo-simulado div.vamos-comecar p {
        margin-bottom: 30px; }

/*form styles*/
#msform {
  width: 100%;
  margin: 10px auto 10px auto;
  text-align: center;
  position: relative;
  padding: 0; }
  #msform select {
    width: 100%;
    line-height: 1.2em;
    padding: 5px;
    font-size: 1.1em;
    margin-top: 10px;
    text-align: center; }
  #msform .simulado-label {
    font-size: 1.1em; }
  #msform .desconsidera-resolvidas {
    font-size: 1em;
    cursor: pointer;
    margin-top: 8px; }
    #msform .desconsidera-resolvidas i.fa-check-square-o {
      color: #ff6722; }
  #msform div.triangulo-canto {
    position: absolute;
    top: -10px;
    right: -35px;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 50px solid #27AE60;
    transform: rotate(45deg); }
  #msform div.triangulo-vermelho {
    border-bottom: 50px solid #cb1919; }
  #msform span.total-questoes {
    position: absolute;
    width: 45px;
    text-align: center;
    color: white;
    font-size: 0.9em;
    top: 10px;
    right: -4px; }

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  width: 100%;
  padding: 10px 5px;
  /*stacking fieldsets above each other*/
  position: relative; }
  @media (min-width: 768px) {
    #msform fieldset {
      padding: 20px; } }
  #msform fieldset div.arvore-disciplinas {
    text-align: left; }
  #msform fieldset .btn-simulado {
    position: relative;
    margin-top: 5px;
    width: 100%;
    padding: 10px 35px; }
    #msform fieldset .btn-simulado:hover {
      background-color: #ff6722;
      color: white; }
    #msform fieldset .btn-simulado i.selecao {
      position: absolute;
      left: 10px;
      top: 12px;
      font-size: 1.2em; }
    #msform fieldset .btn-simulado span.nome-area {
      font-weight: bold;
      font-size: 1.1em; }
    #msform fieldset .btn-simulado span.contador-questao {
      position: absolute;
      top: -2px;
      left: 2px;
      font-size: 0.5em;
      width: 75px; }
  #msform fieldset .tabulado {
    margin-left: 30px; }
    #msform fieldset .tabulado button.btn-simulado {
      text-align: left; }
  #msform fieldset div.estilo-placar {
    position: relative;
    width: 100%;
    background-color: #ff6722;
    padding: 10px 5px 5px;
    margin-top: 10px;
    text-align: center; }
    #msform fieldset div.estilo-placar .titulo {
      font-size: 1.2em;
      color: white;
      margin-bottom: 10px; }
    #msform fieldset div.estilo-placar input.form-control {
      border: none;
      box-shadow: none;
      font-size: 1.4em;
      width: 100%;
      background-color: white;
      text-align: center;
      color: grey; }
    #msform fieldset div.estilo-placar div.input-placar {
      width: 100%;
      text-align: center;
      font-size: 1.2em;
      color: grey;
      padding: 6px 12px;
      background-color: white; }
      #msform fieldset div.estilo-placar div.input-placar input {
        padding: 0;
        margin: 0;
        border: none;
        box-shadow: none;
        font-size: 1.4em;
        width: 30px;
        text-align: right;
        color: grey; }
        #msform fieldset div.estilo-placar div.input-placar input:focus {
          outline: none; }
      #msform fieldset div.estilo-placar div.input-placar input.questoes {
        width: 100%;
        text-align: center; }

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none; }

/*buttons*/
#msform .action-button {
  min-width: 100px;
  background: #27AE60;
  font-weight: bold;
  color: white;
  border: 0 none;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px; }

#msform .action-button:hover, #msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #27AE60; }

/*headings*/
.fs-title {
  font-size: 15px;
  text-transform: uppercase;
  color: #2C3E50;
  margin: 10px auto; }

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px; }

/*progressbar*/
#progressbar {
  margin-bottom: 20px;
  padding: 0;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step; }

#progressbar li {
  list-style-type: none;
  color: #333333;
  text-transform: uppercase;
  font-size: 1.1em;
  width: 33.33%;
  float: left;
  position: relative; }

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  line-height: 40px;
  display: block;
  font-size: 14px;
  color: #333;
  background: white;
  border-radius: 20px;
  margin: 0 auto 5px auto; }

/*progressbar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 4px;
  background: white;
  position: absolute;
  left: -50%;
  top: 18px;
  z-index: -1;
  /*put it behind the numbers*/ }

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none; }

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before, #progressbar li.active:after {
  background: #27AE60;
  color: white; }

#pagina-rankings #tabs {
  border-bottom: 2px solid #c3c3c3; }
  #pagina-rankings #tabs .tab-link {
    display: inline-block;
    padding: 5px 10px;
    font-size: 1.2em;
    cursor: pointer; }
    #pagina-rankings #tabs .tab-link.ativa, #pagina-rankings #tabs .tab-link:hover {
      font-size: 1.3em;
      font-weight: bold;
      color: #ff6722; }
    #pagina-rankings #tabs .tab-link.ativa {
      box-shadow: inset 0 -4px 0 #ff6722; }

#pagina-rankings #conteudo-tabs-rankings .primeiro-do-ranking {
  background-color: #d7fbdeb3; }

#pagina-rankings #conteudo-tabs-rankings .tabela-ranking {
  font-size: 1em;
  margin: 15px 0 0 0;
  color: dimgrey; }
  #pagina-rankings #conteudo-tabs-rankings .tabela-ranking td.posicao {
    width: 50px;
    min-width: 10px;
    color: #ff6722; }
  #pagina-rankings #conteudo-tabs-rankings .tabela-ranking td.avatar {
    padding: 15px; }
    #pagina-rankings #conteudo-tabs-rankings .tabela-ranking td.avatar img {
      height: 70px;
      width: 70px;
      border-radius: 50%; }
  #pagina-rankings #conteudo-tabs-rankings .tabela-ranking td.nick {
    width: 250px;
    padding-left: 20px; }
  #pagina-rankings #conteudo-tabs-rankings .tabela-ranking td.pontos {
    width: 150px;
    color: #ff6722; }

@media (min-width: 769px) {
  #pagina-rankings #conteudo-tabs-rankings .tabela-ranking {
    font-size: 1.3em; } }

#pagina-ajuda .minitutoriais-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

#pagina-ajuda .card-minitutorial {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background: #ff6722;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  transition: all .2s ease; }
  #pagina-ajuda .card-minitutorial:hover {
    transform: scale(1.1); }
  #pagina-ajuda .card-minitutorial .icone-minitutorial {
    color: white;
    font-size: 4em; }
  #pagina-ajuda .card-minitutorial .desc-minitutorial {
    color: white;
    font-size: 1.35em;
    text-align: center; }

#pagina-ajuda .cover-gif {
  cursor: pointer;
  margin: auto;
  transition: all 200ms; }
  #pagina-ajuda .cover-gif:hover {
    transform: scale(1.05); }

#pagina-ajuda #div-filtra-duvida {
  margin-bottom: 20px; }
  #pagina-ajuda #div-filtra-duvida #filtra-duvida {
    font-size: 1.3em;
    height: auto;
    padding: 10px;
    color: #333333; }
  #pagina-ajuda #div-filtra-duvida #icone-busca i.fa {
    font-size: 1.5em;
    color: #c3c3c3;
    margin-top: 12px; }

#pagina-ajuda div.duvida {
  width: 100%; }
  #pagina-ajuda div.duvida .pergunta-faq {
    font-size: 1.1em;
    padding: 5px 0;
    cursor: pointer; }
    #pagina-ajuda div.duvida .pergunta-faq i.fa {
      transition: all .5s ease; }
    #pagina-ajuda div.duvida .pergunta-faq:hover {
      color: #ff6722; }
  #pagina-ajuda div.duvida .resposta-faq {
    font-size: 1em;
    text-align: justify;
    padding: 5px;
    background-color: #F5F5F5; }
    #pagina-ajuda div.duvida .resposta-faq a {
      color: #ff6722; }
  @media (min-width: 768px) {
    #pagina-ajuda div.duvida .pergunta-faq {
      font-size: 1.3em; }
    #pagina-ajuda div.duvida .resposta-faq {
      font-size: 1.1em;
      padding: 10px; } }

#perfil-usuario .painel-sombreado {
  position: relative;
  padding-top: 45px;
  margin-top: 30px; }

#perfil-usuario #avatar-perfil {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50px;
  left: calc(50% - 50px);
  border-radius: 50%;
  border: 4px solid #ff6722; }

@media (min-width: 769px) {
  #perfil-usuario .painel-sombreado {
    font-size: 1.2em; } }

#perfil-usuario .sobre-mim {
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  font-style: italic; }

#perfil-usuario .info-padrao {
  padding: 20px 0;
  margin: 10px 0;
  font-size: 1.2em;
  text-align: center;
  background-color: #f5f5f5; }

#meu-painel {
  background: #F5F5F1;
  height: 100%;
  overflow-y: scroll;
  margin: 0;
  padding-left: 240px; }
  #meu-painel .loading-tab {
    position: absolute;
    top: 25vh;
    left: 35vw;
    font-size: 6em;
    color: #ff6722; }
  #meu-painel #conteudo-painel {
    position: relative;
    min-height: 90%; }
    #meu-painel #conteudo-painel ul.tabs-meu-painel {
      margin-bottom: 4px;
      padding: 0;
      list-style: none; }
      #meu-painel #conteudo-painel ul.tabs-meu-painel > li {
        float: left;
        cursor: pointer;
        /*@media (min-width: $screen-md-min) {
          > a {
            padding: 5px 15px;
            font-size: 1.4em;
          }
        }*/ }
        #meu-painel #conteudo-painel ul.tabs-meu-painel > li > a {
          text-decoration: none;
          color: dimgrey;
          font-size: 1.4em;
          padding: 5px 12px; }
        #meu-painel #conteudo-painel ul.tabs-meu-painel > li.ativo > a, #meu-painel #conteudo-painel ul.tabs-meu-painel > li:hover > a {
          color: #ff6722; }
        #meu-painel #conteudo-painel ul.tabs-meu-painel > li.ativo > a {
          box-shadow: inset 0 -5px 0 #ff6722; }
    #meu-painel #conteudo-painel div.borda-de-topo {
      border: 2px solid #0B486B; }
    #meu-painel #conteudo-painel #conteudo-tabs {
      padding: 10px 0;
      position: relative; }
      #meu-painel #conteudo-painel #conteudo-tabs .titulo-tab {
        font-size: 1.8em;
        margin: 0 0 15px 0; }
      #meu-painel #conteudo-painel #conteudo-tabs .subtitulo-tab {
        margin-bottom: 15px; }
      #meu-painel #conteudo-painel #conteudo-tabs .tab {
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%; }
      #meu-painel #conteudo-painel #conteudo-tabs .carregando-conteudo-tab {
        position: absolute;
        top: 100px;
        left: 40px;
        font-size: 60px;
        color: #ff6722; }
    #meu-painel #conteudo-painel .placa {
      width: 280px;
      padding: 5px;
      background-color: #0B486B;
      text-align: center;
      margin-bottom: 15px; }
      #meu-painel #conteudo-painel .placa .titulo {
        font-size: 1.3em;
        color: white;
        font-weight: bold;
        padding: 5px 0 10px 0; }
        #meu-painel #conteudo-painel .placa .titulo a {
          color: white; }
        #meu-painel #conteudo-painel .placa .titulo .explicacao {
          vertical-align: top;
          font-size: 60%;
          cursor: pointer;
          color: #ededed; }
      #meu-painel #conteudo-painel .placa .valor {
        background-color: white;
        padding: 10px;
        font-size: 1.8em;
        color: #ff6722; }
        #meu-painel #conteudo-painel .placa .valor .icone-placa {
          position: absolute;
          left: -1.4em;
          top: 0.2em;
          color: #c3c3c3; }

.titulo-painel {
  margin: 0 0 25px 0; }
  .titulo-painel .info {
    font-size: 60%;
    vertical-align: top; }

.GaugeMeter {
  position: Relative;
  text-align: Center;
  overflow: Hidden;
  cursor: Default;
  display: inline-block; }

.GaugeMeter SPAN, .GaugeMeter B {
  width: 54%;
  position: Absolute;
  text-align: Center;
  display: Inline-Block;
  color: RGBa(0, 0, 0, 0.8);
  font-weight: 100;
  font-family: "Open Sans", Arial, sans-serif;
  overflow: Hidden;
  white-space: NoWrap;
  text-overflow: Ellipsis;
  margin: 0 23%; }

.GaugeMeter[data-style="Semi"] B {
  width: 80%;
  margin: 0 10%; }

.GaugeMeter S, .GaugeMeter U {
  text-decoration: None;
  font-size: .60em;
  font-weight: 200;
  opacity: .6; }

.GaugeMeter B {
  color: #000;
  font-weight: 200;
  opacity: .8; }

#menu-meu-painel {
  background: #0B486B;
  height: 100vh;
  width: 240px;
  position: fixed;
  top: 53px;
  left: 0;
  z-index: 5;
  outline: none; }
  #menu-meu-painel .avatar {
    background: rgba(0, 0, 0, 0.1);
    padding: 2em 0.5em;
    text-align: center; }
    #menu-meu-painel .avatar img {
      width: 100px;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid #F5F5F1;
      box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2); }
    #menu-meu-painel .avatar h2 {
      font-weight: normal;
      font-size: 1.2em;
      margin-bottom: 0;
      color: white; }
  #menu-meu-painel ul {
    list-style: none;
    padding: 0.5em 0;
    margin: 0;
    color: white; }
    #menu-meu-painel ul li {
      padding: .8em .8em .8em 3em;
      font-size: 1.1em;
      font-weight: normal;
      transition: all 0.15s linear;
      cursor: pointer;
      width: 100%;
      text-align: left; }
      #menu-meu-painel ul li:hover {
        background-color: rgba(0, 0, 0, 0.1); }
      #menu-meu-painel ul li:focus {
        outline: none; }
    #menu-meu-painel ul li.ativo {
      background-color: rgba(0, 0, 0, 0.1);
      border-left: solid #ff6722 4px;
      font-weight: bold; }

/* Menu para Celular/tablet */
@media screen and (max-width: 992px) {
  div#meu-painel {
    padding-left: 0; }
    div#meu-painel #menu-meu-painel {
      width: 240px; }
      div#meu-painel #menu-meu-painel .avatar {
        padding: 0.5em; }
        div#meu-painel #menu-meu-painel .avatar img {
          width: 60px; }
        div#meu-painel #menu-meu-painel .avatar h2 {
          color: white;
          font-size: 1.1em;
          margin-top: 0.5em; }
      div#meu-painel #menu-meu-painel ul li {
        font-size: 1.1em; } }

div#conteudo-tabs .editar-perfil {
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
  vertical-align: top; }
  div#conteudo-tabs .editar-perfil:hover {
    color: #ff6722; }

div#conteudo-tabs div.botoes-cancela-salva {
  margin-top: 5px; }
  div#conteudo-tabs div.botoes-cancela-salva button {
    width: 120px; }

div#conteudo-tabs div.form-group > label {
  font-size: 1.2em; }

div#conteudo-tabs div.form-group > input.form-control, div#conteudo-tabs div.form-group > select.form-control {
  font-size: 1.2em;
  padding: 0.5em;
  height: auto; }

div#conteudo-tabs div.form-group > textarea.form-control {
  font-size: 1.2em;
  min-width: 100%;
  max-width: 100%; }

.select-colecao {
  margin-bottom: 20px; }
  .select-colecao i.excluir-colecao {
    position: absolute;
    top: 30%;
    right: -10px;
    cursor: pointer;
    font-size: 1.3em; }
    .select-colecao i.excluir-colecao:hover {
      color: #ff6722; }
  .select-colecao select.form-control {
    font-size: 1.2em;
    padding: 0.5em;
    height: auto; }

#carregando-questoes {
  position: absolute;
  left: calc(50% - 25px);
  top: 60px;
  font-size: 50px;
  color: #ff6722; }

tr.cabecalho-tabela-azul > th {
  background-color: #0B486B;
  color: white; }

.th-centralizado {
  text-align: center; }

.nenhuma-colecao {
  font-style: italic; }

tr.cabecalho-tabela-desempenho > th {
  background-color: #27AE60;
  color: white;
  font-size: 1.1em; }

tr.cabecalho-tabela-desempenho > th.align-middle {
  vertical-align: middle; }

tr.align-middle > td.align-middle {
  vertical-align: middle; }

#lista-simulados .nome-simulado {
  margin-top: 0; }
  #lista-simulados .nome-simulado:hover {
    cursor: pointer;
    color: #ff6722; }

#lista-simulados .lista-questoes-simulado {
  margin-bottom: 15px;
  list-style: none;
  background-color: white;
  padding: 5px 0 5px 20px; }
  #lista-simulados .lista-questoes-simulado li {
    padding: 5px;
    width: 120px;
    display: inline-block; }
    #lista-simulados .lista-questoes-simulado li:hover {
      background-color: #ebebeb; }

td.sem-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.contagem-exibidos {
  font-style: italic;
  text-align: right; }

#meu-painel div.form-group {
  margin-bottom: 10px; }
  #meu-painel div.form-group > label {
    font-size: 1.2em; }
  #meu-painel div.form-group > input.form-control {
    font-size: 1.2em;
    padding: 0.5em;
    height: auto; }

#meu-painel .botoes-enviar-convite {
  margin-top: 0;
  margin-bottom: 35px; }
  #meu-painel .botoes-enviar-convite button {
    width: 150px; }

/*# sourceMappingURL=app.css.map */
